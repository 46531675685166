import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Livecoding Recap: A new more versatile React pattern",
  "description": "",
  "date": "2018-03-27T08:00:00.000Z",
  "published": "2018-03-27T08:00:00.000Z",
  "image": ""
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><em parentName="p">{`This is a Livecoding Recap – an almost-weekly post about interesting things discovered while livecoding. Usually shorter than 500 words. Often with pictures. Livecoding happens almost `}<strong parentName="em">{`every Sunday at 2pm PDT`}</strong>{` on multiple channels. You should subscribe to `}<a parentName="em" {...{
          "href": "https://www.youtube.com/user/TheSwizec"
        }}>{`my YouTube channel`}</a>{` to catch me live.`}</em></p>
    <lite-youtube {...{
      "videoid": "OnLho6re1aI",
      "videostartat": "0"
    }}></lite-youtube>
    <p>{`I'm working on a React & D3 library that I've been thinking about for two years. My goal is to build `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/simple-react-d3"
      }}>{`simple-react-d3`}</a>{`, a React and D3 library that never gets in your way.`}</p>
    <p>{`Most libraries give you composable and reusable charting components that are easy to use and quick to get started with. They’re great for simple charts.`}</p>
    <p>{`But more often than not, the more custom you want your dataviz to be, the more control you need. You begin to fight your library.`}</p>
    <p><a parentName="p" {...{
        "href": "https://vx-demo.now.sh/"
      }}>{`VX`}</a>{` comes closest to the ideal get-out-of-your-way library, and even VX when I recommended it to a friend it took all of 10 minutes for him to hit the wall. `}<em parentName="p">{`"Wtf how do I do this? The library is fighting me"`}</em></p>
    <p>{`😕`}</p>
    <p>{`The best way to get started was to generalize the `}<inlineCode parentName="p">{`D3Blackbox`}</inlineCode>{` pattern I developed for `}<a parentName="p" {...{
        "href": "https://swizec.com/reactd3js"
      }}>{`React+D3`}</a>{`. It's the easiest and quickest way to render a random piece of D3 code in your React project.`}</p>
    <p>{`Here's an example`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/5v21r0wo4x?module=%2Fsrc%2FAxis.js",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <p>{`Take some D3 code, wrap it in a function, pass it to a HOC (higher order component), and you're done. The HOC renders an anchor element, and your render function uses D3 to take over and manipulate the DOM.`}</p>
    <p>{`This approach doesn't give you all the benefits of React, and it doesn't scale very well. It's meant for simple components, quick hack jobs, or when you have a lot of existing D3 code you want to use. It is the easiest and quickest way to `}<a parentName="p" {...{
        "href": "https://swizec.com/blog/translate-random-d3-example-react/swizec/7765"
      }}>{`translate any random D3 example to React`}</a>{`.`}</p>
    <p>{`Turning that into a library was pretty easy 👇`}</p>
    <pre><code parentName="pre" {...{}}>{`$ nwb new react-component simple-react-d3
<copy pasta>
`}</code></pre>
    <p>{`Boom. Library.`}</p>
    <p>{`But what if you're the kind of person who doesn't like HOCs? Maybe you prefer render props or function-as-children?`}</p>
    <p><img parentName="p" {...{
        "src": "/ced51e14c45cb3bc518082b0450a431a/media-oDOnRIDjxlCzC-giphy.gif",
        "alt": null
      }}></img></p>
    <h2 {...{
      "id": "a-react-component-that-supports-all-reuse-patterns"
    }}>{`A React component that supports `}<em parentName="h2">{`all`}</em>{` reuse patterns`}</h2>
    <p><em parentName="p">{`"Can we make this HOC work as not a HOC too? What if it supported it all popular React patterns for reuse"`}</em></p>
    <p>{`I came up with this`}</p>
    <div><div parentName="div" {...{
        "className": "static-tweet-embed"
      }}>{`
        `}<a parentName="div" {...{
          "className": "author",
          "href": "https://t.co/VuU1lFnIe7"
        }}><img parentName="a" {...{
            "src": "https://pbs.twimg.com/profile_images/1423736293385662466/AnF0Fsi6_normal.jpg",
            "loading": "lazy",
            "alt": "Swizec Teller writing a secret book avatar"
          }}></img><b parentName="a">{`Swizec Teller writing a secret book`}</b>{`@Swizec`}</a>{`
        `}<blockquote parentName="div">{`On a scale of 1 to 10, how terrible is this `}<a parentName="blockquote" {...{
            "href": "https://twitter.com/hashtag/react"
          }}>{`#react`}</a>{` pattern? `}<br parentName="blockquote"></br><br parentName="blockquote"></br><a parentName="blockquote" {...{
            "href": "https://twitter.com/dan_abramov"
          }}>{`@dan_abramov`}</a>{` how much would this mess with stuff from 16.3? `}</blockquote>{`
        `}<div parentName="div" {...{
          "className": "media"
        }}><img parentName="div" {...{
            "src": "https://pbs.twimg.com/media/DZLU95tVAAIgGab.png",
            "width": "100%",
            "loading": "lazy",
            "alt": "Tweet media"
          }}></img></div>{`
        `}<div parentName="div" {...{
          "className": "time"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/978078833076142080"
          }}>{`1:19:00 AM – 3/26/2018`}</a></div>{`
        `}<div parentName="div" {...{
          "className": "stats"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/intent/like?tweet_id=978078833076142080",
            "className": "like"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr",
              "style": {}
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z"
                }}></path></g></svg>{`4`}</a>{` `}<a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/978078833076142080",
            "className": "reply"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"
                }}></path></g></svg>{`2`}</a></div>{`
    `}</div></div>
    <p>{`It's a function, `}<inlineCode parentName="p">{`SVGBlackbox`}</inlineCode>{`, that takes a function as argument and acts like a HOC. Pass in a func, get a component back. Just like the `}<inlineCode parentName="p">{`D3Blackbox`}</inlineCode>{` example above.`}</p>
    <p>{`You can also use it directly as `}<inlineCode parentName="p">{`<SVGBlackbox />`}</inlineCode>{`. If you do that, you can either pass a `}<inlineCode parentName="p">{`render`}</inlineCode>{` prop, or a function-as-children. Both get a reference to the anchor element as their sole argument.`}</p>
    <p>{`Now you can use the blackbox approach any way you like.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const Axis = SVGBlackbox(function () {
  const scale = d3.scaleLinear().domain([0, 10]).range([0, 200]);
  const axis = d3.axisBottom(scale);

  d3.select(this.refs.anchor).call(axis);
});

class Demo extends Component {
  render() {
    return (
      <div>
        <h1>simple-react-d3 demo</h1>
        <svg width="300" height="200">
          <axis x={10} y={10}></axis>
          <svgblackbox x={10} y={50}>
            {(anchor) => {
              const scale = d3.scaleLinear().domain([0, 10]).range([0, 200]);
              const axis = d3.axisBottom(scale);

              d3.select(anchor).call(axis);
            }}
          </svgblackbox>
        </svg>
      </div>
    );
  }
}
`}</code></pre>
    <p>{`That renders two axes. One above the other.`}</p>
    <p><img parentName="p" {...{
        "src": "https://swizec.com/blog/wp-content/uploads/2018/03/demo-screenshot.png",
        "alt": null
      }}></img></p>
    <p>{`Neat.`}</p>
    <p>{`Unfortunately, the internet told me this is a terrible pattern, and I should feel bad. The `}<inlineCode parentName="p">{`window.requestAnimationFrame`}</inlineCode>{` part can lead to all sorts of problems and likely clashes with the future we're getting in React 16.3.`}</p>
    <p>{`However, Sophie Alpert had some good suggestions 👇`}</p>
    <div><div parentName="div" {...{
        "className": "static-tweet-embed"
      }}>{`
        `}<a parentName="div" {...{
          "className": "author",
          "href": "https://t.co/7n9Dlhq0Rb"
        }}><img parentName="a" {...{
            "src": "https://pbs.twimg.com/profile_images/1396684030913830912/pMZ4ENby_normal.jpg",
            "loading": "lazy",
            "alt": "Sophie Alpert avatar"
          }}></img><b parentName="a">{`Sophie Alpert`}</b>{`@sophiebits`}</a>{`
        `}<blockquote parentName="div"><a parentName="blockquote" {...{
            "href": "https://twitter.com/Swizec"
          }}>{`@Swizec`}</a>{` `}<a parentName="blockquote" {...{
            "href": "https://twitter.com/dan_abramov"
          }}>{`@dan_abramov`}</a>{` You need the rAF call to be triggered in componentDidMount (maybe without the rAF). Otherwise it might be called before the node even exists (or while it is in the middle of an update).`}</blockquote>{`
        
        `}<div parentName="div" {...{
          "className": "time"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/sophiebits/status/978096124824137728"
          }}>{`2:27:42 AM – 3/26/2018`}</a></div>{`
        `}<div parentName="div" {...{
          "className": "stats"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/intent/like?tweet_id=978096124824137728",
            "className": "like"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr",
              "style": {}
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z"
                }}></path></g></svg>{`1`}</a>{` `}<a parentName="div" {...{
            "href": "https://twitter.com/sophiebits/status/978096124824137728",
            "className": "reply"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"
                }}></path></g></svg>{`1`}</a></div>{`
    `}</div></div>
    <div><div parentName="div" {...{
        "className": "static-tweet-embed"
      }}>{`
        `}<a parentName="div" {...{
          "className": "author",
          "href": "https://t.co/7n9Dlhq0Rb"
        }}><img parentName="a" {...{
            "src": "https://pbs.twimg.com/profile_images/1396684030913830912/pMZ4ENby_normal.jpg",
            "loading": "lazy",
            "alt": "Sophie Alpert avatar"
          }}></img><b parentName="a">{`Sophie Alpert`}</b>{`@sophiebits`}</a>{`
        `}<blockquote parentName="div"><a parentName="blockquote" {...{
            "href": "https://twitter.com/Swizec"
          }}>{`@Swizec`}</a>{` `}<a parentName="blockquote" {...{
            "href": "https://twitter.com/dan_abramov"
          }}>{`@dan_abramov`}</a>{` Make it a class? You can also return a child component that is a class. It’s also possible to do this logic in a ref callback but that’s a little obtuse.`}</blockquote>{`
        
        `}<div parentName="div" {...{
          "className": "time"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/sophiebits/status/978123160947122180"
          }}>{`4:15:08 AM – 3/26/2018`}</a></div>{`
        `}<div parentName="div" {...{
          "className": "stats"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/intent/like?tweet_id=978123160947122180",
            "className": "like"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr",
              "style": {}
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z"
                }}></path></g></svg>{`4`}</a>{` `}<a parentName="div" {...{
            "href": "https://twitter.com/sophiebits/status/978123160947122180",
            "className": "reply"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"
                }}></path></g></svg>{`0`}</a></div>{`
    `}</div></div>
    <p>{`The idea of shoving render prop stuff into the `}<inlineCode parentName="p">{`ref`}</inlineCode>{` callback smells like black magic. It's so crazy that it might just work.`}</p>
    <h2 {...{
      "id": "the-ultimate-reusable-component"
    }}>{`The ultimate reusable component`}</h2>
    <lite-youtube {...{
      "videoid": "EWVT1Cp8F4o",
      "videostartat": "0"
    }}></lite-youtube>
    <p>{`Another livecoding session later, we did it: The ultimate reusable component. You can use it as a HOC, with render-props, or function-as-children.`}</p>
    <p>{`Less than 2 minutes to grab a random D3 example from the internet and render it in a React app. Still blackbox, but works really well.`}</p>
    <p><a parentName="p" {...{
        "href": "https://youtu.be/EWVT1Cp8F4o?t=574"
      }}>{`Starts at about 9:30`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://youtu.be/EWVT1Cp8F4o?t=863"
      }}>{`Wrapped and embedded at 14:23`}</a></p>
    <p>{`It took 5 minutes because we had to update code from D3v4 to D3v5 and add some prop passing to `}<inlineCode parentName="p">{`SVGBlackbox`}</inlineCode>{` to make it easier to use.`}</p>
    <p><img parentName="p" {...{
        "src": "https://swizec.com/blog/wp-content/uploads/2018/03/svgblackbox-example.gif",
        "alt": null
      }}></img></p>
    <p>{`You can see `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/simple-react-d3/blob/master/src/SVGBlackbox.js"
      }}>{`full SVGBlackbox code on GitHub`}</a>{`. Here's how the interesting part works:`}</p>
    <p><img parentName="p" {...{
        "src": "https://swizec.com/blog/wp-content/uploads/2018/03/finished-svgblackbox.png",
        "alt": "SVGBlackbox"
      }}></img></p>
    <p>{`When used as a HOC, it takes your argument as the render function and passes it into the usual D3Blackbox HOC. Wires up invocation as required and hands control over to you.`}</p>
    <p>{`When used as a component, the argument is a `}<inlineCode parentName="p">{`props`}</inlineCode>{` object. Take out `}<inlineCode parentName="p">{`children`}</inlineCode>{` and `}<inlineCode parentName="p">{`render`}</inlineCode>{`, store the rest as `}<inlineCode parentName="p">{`props`}</inlineCode>{`. Take `}<inlineCode parentName="p">{`x`}</inlineCode>{` and `}<inlineCode parentName="p">{`y`}</inlineCode>{` from that.`}</p>
    <p>{`Then return a `}<inlineCode parentName="p">{`<g>`}</inlineCode>{` element moved into `}<inlineCode parentName="p">{`(x, y)`}</inlineCode>{` position and given all the other props. This can be handy.`}</p>
    <p>{`Now the tricky part: A `}<inlineCode parentName="p">{`ref`}</inlineCode>{` callback that invokes your render function. That's right, you can hand over control of the anchor element in the `}<inlineCode parentName="p">{`ref`}</inlineCode>{` callback.`}</p>
    <p>{`This works, but trips up on `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/refs-and-the-dom.html"
      }}>{`React's caveat about ref callbacks`}</a>{` sometimes.`}</p>
    <blockquote>
      <p parentName="blockquote">{`If the ref callback is defined as an inline function, it will get called twice during updates, first with null and then again with the DOM element. This is because a new instance of the function is created with each render, so React needs to clear the old ref and set up the new one.`}</p>
    </blockquote>
    <p>{`Hence the callback is wrapped in a conditional to check that `}<inlineCode parentName="p">{`anchor`}</inlineCode>{` is defined.`}</p>
    <p>{`Still feels a little dirty, but much better than the `}<inlineCode parentName="p">{`requestAnimationFrame`}</inlineCode>{` approach. Shouldn't mess with async stuff in React 16.3 either, I think.`}</p>
    <h2 {...{
      "id": "next-step"
    }}>{`Next step?`}</h2>
    <p>{`Something similar for the full feature integration where D3 calculates your props and React renders your dataviz. You can see the first part of that towards the end of the 2nd video above.`}</p>
    <p>{`It's all coming together :)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      